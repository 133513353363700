import React from "react";

import tw from "twin.macro";
import styled from "styled-components";
import { SectionHeading } from "components/misc/Headings";
import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons";
import { ReactComponent as PriceIcon } from "feather-icons/dist/icons/dollar-sign.svg";
import { ReactComponent as LocationIcon } from "feather-icons/dist/icons/map-pin.svg";
import { ReactComponent as StarIcon } from "feather-icons/dist/icons/star.svg";

import "./style.css";
const Container = tw.div`relative`;
const Content = tw.div`max-w-screen-xl mx-auto py-16 lg:py-20`;

const HeadingWithControl = tw.div`flex flex-col items-center sm:items-stretch sm:flex-row justify-between`;
const Heading = tw(SectionHeading)`mt-5 text-center [color: black]`;

const Card = tw.div`h-full flex! flex-col sm:border max-w-sm sm:rounded-tl-4xl sm:rounded-br-5xl relative focus:outline-none`;
const CardImage = styled.div(props => [
     `background-image: url("${props.imageSrc}");`,
     tw`w-full h-56 sm:h-64 bg-cover bg-center rounded sm:rounded-none sm:rounded-tl-4xl`
]);

const TextInfo = tw.div`py-6 sm:px-10 sm:py-6`;
const TitleReviewContainer = tw.div`flex flex-col sm:flex-row sm:justify-between sm:items-center`;
const Title = tw.h5`text-2xl font-bold`;

const RatingsInfo = styled.div`
  ${tw`flex items-center sm:ml-4 mt-2 sm:mt-0`}
  svg {
    ${tw`w-6 h-6 text-yellow-500 fill-current`}
  }
`;
const Rating = tw.span`ml-2 font-bold`;

const Description = tw.p`text-sm leading-loose mt-2 sm:mt-4`;

const SecondaryInfoContainer = tw.div`flex flex-col sm:flex-row mt-2 sm:mt-4`;
const IconWithText = tw.div`flex items-center mr-6 my-2 sm:my-0`;
const IconContainer = styled.div`
  ${tw`inline-block rounded-full p-2 bg-gray-700 text-gray-100`}
  svg {
    ${tw`w-3 h-3`}
  }
`;
const Text = tw.div`ml-2 text-sm font-semibold text-gray-800`;
const TextLine = tw.div`[text-decoration: line-through] ml-2 text-sm font-semibold text-gray-800`;

const PrimaryButton = tw(PrimaryButtonBase)`mt-auto sm:text-lg rounded-none w-full [background-color: #F496C3 ] hover:[background-color: #f88fc1] rounded sm:rounded-none sm:rounded-br-4xl py-3 sm:py-6`;
export default () => {
     const card_1 = [
          {
               imageSrc: "https://images.unsplash.com/photo-1571770095004-6b61b1cf308a?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&h=1024&w=768&q=80",
               bookurl: "https://cal.com/nitikatalwarr/individual-counselling-session",
               title: "Individual Counselling session ",
               description: "Whether you're dealing with stress, anxiety, life transitions, or just feeling a bit stuck, I'm here to provide a safe space for you to explore, grow, and find your way",
               locationText: "Audio call",
               pricingText: "₹750",
               originalPrice: "₹900",
               rating: "45 mins",
          },
          {
               imageSrc: "https://images.unsplash.com/photo-1571770095004-6b61b1cf308a?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&h=1024&w=768&q=80",
               bookurl: "https://cal.com/nitikatalwarr/individual-video-counselling",
               title: "Individual Video Counselling ",
               description: " Whether you're dealing with stress, anxiety, life transitions, or just feeling a bit stuck, I'm here to provide a safe space for you to explore, grow, and find your way",
               locationText: "Video Call",
               pricingText: "₹1100",
               // originalPrice: "₹1100",
               rating: "45 mins",
          },
          // {
          //      imageSrc: "https://images.unsplash.com/photo-1571770095004-6b61b1cf308a?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&h=1024&w=768&q=80",
          //      bookurl: "https://cal.com/nitikatalwarr/all-in-one-therapy-session",
          //      title: "All in one Therapy session",
          //      description: "Together, we can work on building resilience, finding clarity, and creating a path towards a more fulfilling life.",
          //      locationText: "Audio call",
          //      pricingText: "₹1200",
          //      rating: "60 mins",
          // },
          {
               imageSrc: "https://images.unsplash.com/photo-1571770095004-6b61b1cf308a?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&h=1024&w=768&q=80",
               bookurl: "https://cal.com/nitikatalwarr/all-in-one-therapy-session-video",
               title: "Reset your Heart Therapy               ",
               description: "1:1 Counselling + Hidden Body Techniques + Surprise Feel good gift. People going through various emotions for examples faced loss, feeling too happy, stuck, too sad depressed.",
               locationText: "Video Call",
               pricingText: "₹1500",
               rating: "60 mins",
          },
          // {
          //      imageSrc: "https://images.unsplash.com/photo-1571770095004-6b61b1cf308a?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&h=1024&w=768&q=80",
          //      bookurl: "https://cal.com/nitikatalwarr/regular-therapy-sessions",
          //      title: "Regular Therapy sessions",
          //      description: "If you're looking for a consistent space to explore your thoughts, feelings, and personal growth, you've found it",
          //      locationText: "Audio + Video",
          //      pricingText: "₹4200",
          //      originalPrice: "₹5000",
          //      rating: "60 mins",
          // },
          {
               imageSrc: "https://images.unsplash.com/photo-1571770095004-6b61b1cf308a?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&h=1024&w=768&q=80",
               bookurl: "https://cal.com/nitikatalwarr/relationship-counseling-session",
               title: "Relationship Counseling session",
               description: "Are you feeling a bit stuck or tangled up in relationship stuff? or are you losing your identity in your relationship? Are you deeply affected by your breakup? Let's get it sorted!  ",
               locationText: "Audio Call",
               pricingText: "₹1000",
               // originalPrice: "5000Rs",
               rating: "45 mins",
          },
          {
               imageSrc: "https://images.unsplash.com/photo-1571770095004-6b61b1cf308a?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&h=1024&w=768&q=80",
               bookurl: "https://cal.com/nitikatalwarr/relationship-video-counseling",
               title: "Relationship Video Counseling",
               description: "Are you feeling a bit stuck or tangled up in relationship stuff? or are you losing your identity in your relationship? Are you deeply affected by your breakup? Let's get it sorted!",
               locationText: "Video Call",
               pricingText: "₹1200",
               // originalPrice: "5000Rs",
               rating: "45 mins",
          },
          // {
          //      imageSrc: "https://images.unsplash.com/photo-1571770095004-6b61b1cf308a?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&h=1024&w=768&q=80",
          //      bookurl: "https://cal.com/nitikatalwarr/couple-counselling-therapy-session",
          //      title: "Couple Counselling Therapy/session",
          //      description: "In my couple's counseling sessions, we'll create a safe space for open and honest conversations. I will be communicating with both you and your partner.",
          //      locationText: "Audio Call ",
          //      pricingText: "₹2000",
          //      // originalPrice: "5000Rs",
          //      rating: "90 mins",
          // },
          // {
          //      imageSrc: "https://images.unsplash.com/photo-1571770095004-6b61b1cf308a?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&h=1024&w=768&q=80",
          //      bookurl: "https://cal.com/nitikatalwarr/couple-counselling-therapy",
          //      title: "Couple Counselling Therapy/session",
          //      description: "In my couple's counseling sessions, we'll create a safe space for open and honest conversations. I will be communicating with both you and your partner.",
          //      locationText: "Video Call ",
          //      pricingText: "₹2400",
          //      // originalPrice: "5000Rs",
          //      rating: "90 mins",
          // },
          // {
          //      imageSrc: "https://images.unsplash.com/photo-1571770095004-6b61b1cf308a?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&h=1024&w=768&q=80",
          //      bookurl: "https://cal.com/nitikatalwarr/career-counselling",
          //      title: "Career Counselling",
          //      description: "In our career counseling sessions, we'll explore your strengths, passions, and goals.",
          //      locationText: "Audio call ",
          //      pricingText: "₹500",
          //      // originalPrice: "5000Rs",
          //      rating: "30 min",
          // },
          // {
          //      imageSrc: "https://images.unsplash.com/photo-1571770095004-6b61b1cf308a?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&h=1024&w=768&q=80",
          //      bookurl: "https://cal.com/nitikatalwarr/color-therapy-individual",
          //      title: "Color Therapy (Individual)               ",
          //      description: "In my color therapy sessions, we'll explore the powerful impact that colors can have on your emotions, energy, and mindset. Whether you're seeking relaxation, inspiration, or a boost in creativity",
          //      locationText: "Video Call ",
          //      pricingText: "₹1200",
          //      // originalPrice: "5000Rs",
          //      rating: "60 mins",
          // },
          // {
          //      imageSrc: "https://images.unsplash.com/photo-1571770095004-6b61b1cf308a?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&h=1024&w=768&q=80",
          //      bookurl: "https://cal.com/nitikatalwarr/color-psychology-therapy-business",
          //      title: "Color Psychology/Therapy (Business)         ",
          //      description: "If you're seeking to enhance your brand, boost productivity, or create a positive environment in your workplace, you're in the right space.",
          //      locationText: "Video Call ",
          //      pricingText: "₹1500",
          //      // originalPrice: "5000Rs",
          //      rating: "60 mins",
          // },
          // {
          //      imageSrc: "https://images.unsplash.com/photo-1571770095004-6b61b1cf308a?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&h=1024&w=768&q=80",
          //      bookurl: "https://cal.com/nitikatalwarr/acupressure-therapy-one-time",
          //      title: "Acupressure Therapy (One Time)         ",
          //      description: "If you're looking for a natural and effective way to relieve stress, alleviate pain, or enhance your overall health, you're in the right place.",
          //      locationText: "Video Call ",
          //      pricingText: "₹1400",
          //      // originalPrice: "5000Rs",
          //      rating: "60 mins",
          // },
          // {
          //      imageSrc: "https://images.unsplash.com/photo-1571770095004-6b61b1cf308a?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&h=1024&w=768&q=80",
          //      bookurl: "https://cal.com/nitikatalwarr/regular-acupressure-therapy-sessions",
          //      title: "Regular Acupressure Therapy sessions               ",
          //      description: "From promoting relaxation to addressing specific health concerns, acupressure therapy is a holistic approach that considers the mind, body, and spirit.",
          //      locationText: "Video Call ",
          //      pricingText: "₹5000",
          //      originalPrice: "₹5600",
          //      rating: "60 mins",
          // },
          {
               imageSrc: "https://images.unsplash.com/photo-1571770095004-6b61b1cf308a?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&h=1024&w=768&q=80",
               bookurl: "https://google.com",
               title: "Child Development Therapy            ",
               description: "If you're a parent navigating the challenges of childhood or have concerns about your child's emotional, social, or behavioral development, you're in the right place.",
               locationText: "Video Call ",
               pricingText: "₹1600               ",
               // originalPrice: "₹5600",
               rating: "60 mins",
          },

     ]


     return (
          <Container>
               <Content>
                    <>
                         <Heading>Therapy</Heading>
                    </>
                    <section className="cols" >
                         {card_1.map((card, index) => (
                              <Card className="cols_card" key={index}>
                                   {/* <CardImage imageSrc={card.imageSrc} /> */}
                                   <TextInfo>
                                        <TitleReviewContainer>
                                             <Title>{card.title}</Title>
                                             <RatingsInfo>
                                                  {/* <StarIcon /> */}
                                                  <Rating>{card.rating}</Rating>
                                             </RatingsInfo>
                                        </TitleReviewContainer>
                                        <SecondaryInfoContainer>
                                             <IconWithText>
                                                  <IconContainer>
                                                       <LocationIcon />
                                                  </IconContainer>
                                                  <Text>{card.locationText}</Text>
                                             </IconWithText>
                                             <IconWithText>
                                                  <IconContainer>
                                                       <PriceIcon />
                                                  </IconContainer>
                                                  <TextLine>{card?.originalPrice}</TextLine>
                                                  <Text>{card.pricingText}</Text>
                                             </IconWithText>
                                        </SecondaryInfoContainer>
                                        <Description>{card.description}</Description>
                                   </TextInfo>
                                   <PrimaryButton href={card.bookurl}>
                                        Book Now
                                   </PrimaryButton>
                              </Card>
                         ))}
                    </section>
               </Content>
          </Container>
     );
};
