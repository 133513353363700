import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import SliderCard from "components/therapist/Services";
import Footer from "components/footers/FiveColumnDark.js";
export default () => (
  <AnimationRevealPage>

    <SliderCard />
    <Footer />
  </AnimationRevealPage>
);
