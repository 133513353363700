import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import { SectionHeading, Subheading as SubheadingBase } from "components/misc/Headings.js";
import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons.js";
import StatsIllustrationSrc from "images/nitika/IMG_5395.webp";
import { ReactComponent as SvgDotPattern } from "images/dot-pattern.svg";
const Container = tw.div`relative [background: #F496C3] text-gray-100 -mx-8 -mb-8 px-8`;
const TwoColumn = tw.div`flex flex-col md:flex-row justify-between max-w-screen-xl mx-auto py-20 md:py-24`;
const Column = tw.div`w-full max-w-md mx-auto md:max-w-none md:mx-0`;
const ImageColumn = tw(Column)`w-full md:w-5/12 flex-shrink-0 h-80 md:h-auto relative`;
const TextColumn = styled(Column)(props => [
  tw`md:w-7/12 mt-16 md:mt-0`,
  props.textOnLeft ? tw`md:mr-12 lg:mr-16 md:order-first` : tw`md:ml-12 lg:ml-16 md:order-last`
]);

const Image = styled.div(props => [
  `background-image: url("${props.imageSrc}");`,
  tw`rounded bg-contain bg-no-repeat w-full bg-center h-full`
]);
const TextContent = tw.div`lg:py-8 text-center md:text-left`;

const Subheading = tw(SubheadingBase)`text-center text-pink-600 md:text-left`;
const Heading = tw(
  SectionHeading
)`mt-4 font-black  text-3xl sm:text-4xl lg:text-5xl text-center md:text-left leading-tight`;
const Description = tw.p`mt-4 text-center md:text-left text-sm md:text-base text-black lg:text-lg font-medium leading-relaxed `;

const Statistics = tw.div`flex flex-col items-center sm:block text-center md:text-left mt-4`;
const Statistic = tw.div`text-left sm:inline-block sm:mr-12 last:mr-0 mt-4`;
const Value = tw.div`font-bold text-lg sm:text-xl lg:text-2xl text-secondary-500 tracking-wide`;
const Key = tw.div`font-medium text-pink-700`;

const PrimaryButton = tw(PrimaryButtonBase)`mt-8 md:mt-10 text-sm inline-block mx-auto md:mx-0`;

const DecoratorBlob = styled(SvgDotPattern)(props => [
  tw`w-20 h-20 absolute right-0 bottom-0 transform translate-x-1/2 translate-y-1/2 fill-current text-pink-500 -z-10`
]);

export default ({
  subheading = "",
  heading = (
    <>Your <wbr /> Therapist Nitika!
    {/* <span tw="text-pink-500"></span>  */}
    </>
  ),
  imageSrc = StatsIllustrationSrc,
  imageCss = null,
  imageContainerCss = null,
  imageDecoratorBlob = false,
  imageDecoratorBlobCss = null,
  imageInsideDiv = true,
  statistics = null,
  textOnLeft = false
}) => {
  // The textOnLeft boolean prop can be used to display either the text on left or right side of the image.
  //Change the statistics variable as you like, add or delete objects
  const defaultStatistics = [
    // {
    //   key: "Clients",
    //   value: "2282+"
    // },
    // {
    //   key: "Projects",
    //   value: "3891+"
    // },
    // {
    //   key: "Awards",
    //   value: "1000+"
    // }
  ];

  if (!statistics) statistics = defaultStatistics;

  return (
    <Container id="about">
      <TwoColumn css={!imageInsideDiv && tw`md:items-center`}>
        <ImageColumn css={imageContainerCss}>
          {imageInsideDiv ? <Image imageSrc={imageSrc} css={imageCss} /> : <img src={imageSrc} css={imageCss} alt="nitika talwar picture" role="img" />}
          {imageDecoratorBlob && <DecoratorBlob css={imageDecoratorBlobCss} />}
        </ImageColumn>
        <TextColumn textOnLeft={textOnLeft}>
          <TextContent>
            {subheading && <Subheading>{subheading}</Subheading>}
            <Heading>{heading}</Heading>
            <Description>
              I am a Certified Therapist, Licensed Acupressure Practitioner (MCA/2024/03/B/004289) & an alternate sciences trainer, certified in Neuropsychology,Relationship Therapy. I Studied nutritional therapy from Lincoln University,I am a postural paramedic,I did my bachelors in Human development from Delhi University. Currently also a podcast host at Karmic Kritya Hindi Podcast - Occult science podcast on Youtube! I have knowledge about alternate sciences, psychology, nutritional therapies and various other Therapeutic tools, and I dream of promoting wellness in terms of being healthy from within, rather than consuming prescribed drugs.
              <br/>
              <br/>
              I am in direct guidance of some top doctors in the same field for which I am very grateful. I have more than 8 years of experience in Content Writing, for 3 years I have been a part of a successful Video & Content marketing agency named – Adornplus Pvt. Ltd. Along with managing a talk show – AdornTalks(Hindi Podcast) 
              I love to interact with people and share my part of knowledge in the most prestigious way. I have a very curious nature, and I am always open to new experiences.
             <br/>
             <br/>
              I am gladly sharing a part of my skills & Knowledge that may help anyone in anyway at any point of time. Soma is the most beautiful and personal thing I started, Soma in biology means body, It is an ancient ayurvedic drink that kept people calm, and it also means MOON, which governs our emotions!

            </Description>
            <Statistics>
              {statistics.map((statistic, index) => (
                <Statistic key={index}>
                  <Value>{statistic.value}</Value>
                  <Key>{statistic.key}</Key>
                </Statistic>
              ))}
            </Statistics>
          </TextContent>
        </TextColumn>
      </TwoColumn>
    </Container>
  );
};
