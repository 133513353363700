import React, { useState } from "react";
import Slider from "react-slick";
import tw from "twin.macro";
import styled from "styled-components";
import { SectionHeading } from "components/misc/Headings";
import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons";
import { ReactComponent as PriceIcon } from "feather-icons/dist/icons/dollar-sign.svg";
import { ReactComponent as LocationIcon } from "feather-icons/dist/icons/map-pin.svg";
import { ReactComponent as StarIcon } from "feather-icons/dist/icons/star.svg";
import { ReactComponent as ChevronLeftIcon } from "feather-icons/dist/icons/chevron-left.svg";
import { ReactComponent as ChevronRightIcon } from "feather-icons/dist/icons/chevron-right.svg";
import { Link } from "react-router-dom";

const Container = tw.div`relative`;
const Content = tw.div`max-w-screen-xl mx-auto py-16 lg:py-20`;

const HeadingWithControl = tw.div`flex flex-col items-center sm:items-stretch sm:flex-row justify-between`;
const Heading = tw(SectionHeading)`mt-5 [color: black]`;
const Controls = tw.div`flex items-center`;
const ControlButton = styled(PrimaryButtonBase)`
  ${tw`mt-4 sm:mt-0 first:ml-0 ml-6 rounded-full p-2`}
  svg {
    ${tw`w-6 h-6`}
  }
`;
const PrevButton = tw(ControlButton)`[background-color: #F496C3]  hover:[background-color: #f88fc1] focus:[background-color: #f88fc1] `;
const NextButton = tw(ControlButton)`[background-color: #F496C3]  hover:[background-color: #f88fc1] focus:[background-color: #f88fc1] `;

const CardSlider = styled(Slider)`
  ${tw`mt-16`}
  .slick-track { 
    ${tw`flex`}
  }
  .slick-slide {
    ${tw`h-auto flex justify-center mb-1`}
  }
`;
const Card = tw.div`h-full flex! flex-col sm:border max-w-sm sm:rounded-tl-4xl sm:rounded-br-5xl relative focus:outline-none`;
const CardImage = styled.div(props => [
  `background-image: url("${props.imageSrc}");`,
  tw`w-full h-56 sm:h-64 bg-cover bg-center rounded sm:rounded-none sm:rounded-tl-4xl`
]);

const TextInfo = tw.div`py-6 sm:px-10 sm:py-6`;
const TitleReviewContainer = tw.div`flex flex-col sm:flex-row sm:justify-between sm:items-center`;
const Title = tw.h5`text-2xl font-bold`;

const RatingsInfo = styled.div`
  ${tw`flex items-center sm:ml-4 mt-2 sm:mt-0`}
  svg {
    ${tw`w-6 h-6 text-yellow-500 fill-current`}
  }
`;
const Rating = tw.span`ml-2 font-bold`;
const Note = tw.h3`text-center text-2xl `

const Description = tw.p`text-sm leading-loose mt-2 sm:mt-4`;

const SecondaryInfoContainer = tw.div`flex flex-col sm:flex-row mt-2 sm:mt-4`;
const IconWithText = tw.div`flex items-center mr-6 my-2 sm:my-0`;
const IconContainer = styled.div`
  ${tw`inline-block rounded-full p-2 bg-gray-700 text-gray-100`}
  svg {
    ${tw`w-3 h-3`}
  }
`;
const Text = tw.div`ml-2 text-sm font-semibold text-gray-800`;
const PrimaryButton = tw(PrimaryButtonBase)`mt-auto sm:text-lg rounded-none w-full [background-color: #F496C3 ] hover:[background-color: #f88fc1] rounded sm:rounded-none sm:rounded-br-4xl py-3 sm:py-6`;
export default () => {
  // useState is used instead of useRef below because we want to re-render when sliderRef becomes available (not null)
  const [sliderRef1, setSliderRef1] = useState(null);
  const [sliderRef2, setSliderRef2] = useState(null);
  const [sliderRef3, setSliderRef3] = useState(null);
  const [sliderRef4, setSliderRef4] = useState(null);
  const sliderSettings = {
    arrows: false,
    slidesToShow: 3.1,
    responsive: [
      {
        breakpoint: 1280,
        settings: {
          slidesToShow: 2.1,
        }
      },

      {
        breakpoint: 900,
        settings: {
          slidesToShow: 1,
        }
      },
    ]
  };

  /* Change this according to your needs */

  const card_1 = [
    {
      imageSrc: "https://images.unsplash.com/photo-1571770095004-6b61b1cf308a?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&h=1024&w=768&q=80",
      bookurl: "https://cal.com/nitikatalwarr/workshops",
      title: "Stress Management with Acupressure",
      description: "A workshop focused on acupressure techniques to alleviate stress and tension commonly experienced in the workplace. Employees can learn specific pressure points and self-massage techniques to reduce stress levels and promote relaxation.",
      locationText: "Rome, Italy",
      pricingText: "USD 39/Day",
      rating: "4.8",
    },
    {
      imageSrc: "https://images.unsplash.com/photo-1571770095004-6b61b1cf308a?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&h=1024&w=768&q=80",
      bookurl: "https://cal.com/nitikatalwarr/workshops",
      title: "Boosting Energy and Productivity",
      description: " This workshop teaches acupressure methods aimed at increasing energy levels and enhancing productivity. Employees can learn how to stimulate specific pressure points to combat fatigue, improve concentration, and boost mental clarity.",
      locationText: "Ibiza, Spain",
      pricingText: "USD 50/Day",
      rating: 4.9,
    },
    {
      imageSrc: "https://images.unsplash.com/photo-1571770095004-6b61b1cf308a?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&h=1024&w=768&q=80",
      bookurl: "https://cal.com/nitikatalwarr/workshops",
      title: "Pain Relief and Ergonomics:",
      description: "Acupressure techniques can be beneficial for relieving common office-related pains such as headaches, neck and shoulder tension, and lower back pain. This workshop provides employees with practical acupressure strategies to manage pain and promote better posture and ergonomics.",
      locationText: "Palo Alto, CA",
      pricingText: "USD 19/Day",
      rating: "5.0",
    },
    {
      imageSrc: "https://images.unsplash.com/photo-1571770095004-6b61b1cf308a?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&h=1024&w=768&q=80",
      bookurl: "https://cal.com/nitikatalwarr/workshops",
      title: "Wellness Breaks",
      description: "Short acupressure sessions integrated into the workday can serve as effective wellness breaks. Employees can participate in brief workshops or training sessions to learn quick acupressure routines they can perform at their desks to relieve stress and tension throughout the day.",
      locationText: "Arizona, RAK",
      pricingText: "USD 99/Day",
      rating: 4.5,
    },
    {
      imageSrc: "https://images.unsplash.com/photo-1571770095004-6b61b1cf308a?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&h=1024&w=768&q=80",
      bookurl: "https://cal.com/nitikatalwarr/workshops",
      title: "Team Building through Acupressure",
      description: "Acupressure workshops can also be designed as team-building activities, fostering a sense of camaraderie and well-being among colleagues. Employees can learn acupressure techniques to use on each other, promoting connection and relaxation within the team.",
      locationText: "Arizona, RAK",
      pricingText: "USD 99/Day",
      rating: 4.5,
    },
    {
      imageSrc: "https://images.unsplash.com/photo-1571770095004-6b61b1cf308a?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&h=1024&w=768&q=80",
      bookurl: "https://cal.com/nitikatalwarr/workshops",
      title: "Mindfulness and Self-Care",
      description: "Incorporating mindfulness practices with acupressure techniques can enhance the overall effectiveness of wellness workshops. Employees can learn how to combine acupressure with breathing exercises and mindfulness techniques to manage stress, increase self-awareness, and promote self-care.      ",
      locationText: "Arizona, RAK",
      pricingText: "USD 99/Day",
      rating: 4.5,
    },
    {
      imageSrc: "https://images.unsplash.com/photo-1571770095004-6b61b1cf308a?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&h=1024&w=768&q=80",
      bookurl: "https://cal.com/nitikatalwarr/workshops",
      title: "Customized Workshops",
      description: " Tailored workshops can be designed to address specific needs and concerns of the corporate workforce. Surveys or assessments can be conducted to identify common issues such as stress, fatigue, or poor posture, and workshops can be customized to provide targeted solutions using acupressure.      ",
      locationText: "Arizona, RAK",
      pricingText: "USD 99/Day",
      rating: 4.5,
    },

  ]
  const card_2 = [
    {
      imageSrc: "https://images.unsplash.com/photo-1571896349842-33c89424de2d?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&h=1024&w=768&q=80",
      bookurl: "https://cal.com/nitikatalwarr/workshops",
      title: "Stress Management for Students",
      description: "Workshops focused on techniques can help students learn how to manage academic stress effectively. They can be taught specific pressure points and self-massage techniques to alleviate stress and anxiety, enabling them to perform better academically and maintain a healthy work-life balance. ",
      locationText: "Rome, Italy",
      pricingText: "USD 39/Day",
      rating: "4.8",
    },
    {
      imageSrc: "https://images.unsplash.com/photo-1571003123894-1f0594d2b5d9?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&h=1024&w=768&q=80",
      bookurl: "https://cal.com/nitikatalwarr/workshops",

      title: "Teacher Wellness Programs",
      description: "Trainings designed for teachers and staff can address the unique challenges they face in the educational environment. These programs can offer techniques to relieve stress, boost energy levels, and prevent burnout, ultimately enhancing job satisfaction and productivity.      ",
      locationText: "Ibiza, Spain",
      pricingText: "USD 50/Day",
      rating: 4.9,
    },
    {
      imageSrc: "https://images.unsplash.com/photo-1549294413-26f195200c16?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&h=1024&w=768&q=80",
      bookurl: "https://cal.com/nitikatalwarr/workshops",

      title: "Study Skills Enhancemen",
      description: " These Workshops can incorporate techniques to improve concentration, memory, and cognitive function, which are essential skills for academic success. Students can learn acupressure methods to stimulate brain function and optimize their learning potential, and also understand the neuropsychology of learning!      ",
      locationText: "Palo Alto, CA",
      pricingText: "USD 19/Day",
      rating: "5.0",
    },
    {
      imageSrc: "https://images.unsplash.com/photo-1571770095004-6b61b1cf308a?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&h=1024&w=768&q=80",
      bookurl: "https://cal.com/nitikatalwarr/workshops",

      title: "Promoting Mental Health Awareness",
      description: "Trainings can be integrated into mental health awareness campaigns within educational institutions. Workshops can focus on self-care strategies using acupressure to support mental health and well-being among students, teachers, and staff.",
      locationText: "Arizona, RAK",
      pricingText: "USD 99/Day",
      rating: 4.5,
    },
    {
      imageSrc: "https://images.unsplash.com/photo-1571770095004-6b61b1cf308a?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&h=1024&w=768&q=80",
      bookurl: "https://cal.com/nitikatalwarr/workshops",

      title: "Empowering Peer Support",
      description: " Peer-led  workshops can empower students to support each other's well-being. Training programs can teach students how to administer basic acupressure & psychological techniques to their peers, fostering a sense of community and support within the school or college.      ",
      locationText: "Arizona, RAK",
      pricingText: "USD 99/Day",
      rating: 4.5,
    },
    {
      imageSrc: "https://images.unsplash.com/photo-1571770095004-6b61b1cf308a?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&h=1024&w=768&q=80",
      bookurl: "https://cal.com/nitikatalwarr/workshops",
      title: "Pre-Exam Stress Relief",
      description: " Workshops held before exams can provide students with practical tools to manage test anxiety and perform at their best. Techniques such as acupressure massage, breathing exercises, and mindfulness practices can help students feel more calm, focused, and confident during exams.      ",
      locationText: "Arizona, RAK",
      pricingText: "USD 99/Day",
      rating: 4.5,
    },
    {
      imageSrc: "https://images.unsplash.com/photo-1571770095004-6b61b1cf308a?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&h=1024&w=768&q=80",
      bookurl: "https://cal.com/nitikatalwarr/workshops",

      title: "Parent-Child Workshops",
      description: "  Educational institutions can offer  workshops that involve both parents and children. These workshops can focus on teaching parents acupressure & Psychological techniques to support their children's well-being, both academically and emotionally.      ",
      locationText: "Arizona, RAK",
      pricingText: "USD 99/Day",
      rating: 4.5,
    },
    {
      imageSrc: "https://images.unsplash.com/photo-1571770095004-6b61b1cf308a?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&h=1024&w=768&q=80",
      bookurl: "https://cal.com/nitikatalwarr/workshops",
      title: "Integration with Wellness Programs",
      description: "Acupressure training can be integrated into broader wellness programs within educational institutions. They can complement other wellness initiatives such as yoga, meditation, and nutrition workshops, providing students, teachers, and staff with a holistic approach to well-being.      ",
      locationText: "Arizona, RAK",
      pricingText: "USD 99/Day",
      rating: 4.5,
    },

  ]
  const card_3 = [
    {
      imageSrc: "https://images.unsplash.com/photo-1571770095004-6b61b1cf308a?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&h=1024&w=768&q=80",
      bookurl: "https://cal.com/nitikatalwarr/workshops",
      title: "Emotional Intelligence",
      description: "Participantslearn strategies to enhance emotional intelligence, understand emotions, manage and express them, and rectify any emotional wrongs. The workshop includes an overview of emotional intelligence, practical techniques for daily life, and its impact on personal and professional success.",
      locationText: "Rome, Italy",
      pricingText: "USD 39/Day",
      rating: "4.8",
    },
    {
      bookurl: "https://cal.com/nitikatalwarr/workshops",

      imageSrc: "https://images.unsplash.com/photo-1571896349842-33c89424de2d?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&h=1024&w=768&q=80",
      title: "Colour Psychology",
      description: "The Power of Colour: Understanding Colour Psychology and its Applications In Daily Life. This Interactive workshop explores the impact of colors on emotions, behavior, and perceptions.It covers the meaning and symbolism of colors, and their applications in branding, marketing, fashion, interior design, and visual art and daily life. Suitable for anyone interested in Psychology of colors      .",
      locationText: "Rome, Italy",
      pricingText: "USD 39/Day",
      rating: "4.8",
    },
    {
      bookurl: "https://cal.com/nitikatalwarr/workshops",

      imageSrc: "https://images.unsplash.com/photo-1571003123894-1f0594d2b5d9?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&h=1024&w=768&q=80",
      title: "Maintaining Relationships",
      description: "This Interactive workshop focuses on relationship psychology, exploring various dynamics between individuals, groups, and societies. Participants will delve into communication, attachment styles, and emotional intelligence. The workshop covers love languages, family relationships, and how to be socially better, with interactive activities and exercises to develop practical skills. ",
      locationText: "Ibiza, Spain",
      pricingText: "USD 50/Day",
      rating: 4.9,
    },
    {
      bookurl: "https://cal.com/nitikatalwarr/workshops",

      imageSrc: "https://images.unsplash.com/photo-1549294413-26f195200c16?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&h=1024&w=768&q=80",
      title: "Social Media Psychology",
      description: "This workshop is specially designed for youngsters who are prone to cyber bullying and criminal activities online, how to prevent oneself from them, how to shine in social media without getting negative about it, discussing some social media patterns, How it influences our daily life and what best we can derive from it. Some Online tools to manage your daily life better!      ",
      locationText: "Palo Alto, CA",
      pricingText: "USD 19/Day",
      rating: "5.0",
    },
    {
      bookurl: "https://cal.com/nitikatalwarr/workshops",

      imageSrc: "https://images.unsplash.com/photo-1571770095004-6b61b1cf308a?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&h=1024&w=768&q=80",
      title: "Self Improvement",
      description: "This workshop on self-improvement covers learn self-acceptance,self worth,self-motivation, ego's role, and how attachments impact growth. Group discussions, personal reflection, and practical techniques to better understand yourself, develop a positive self-image, and move forward on your journey of growth. Perfect for anyone seeking self awareness and confidence.",
      locationText: "Arizona, RAK",
      pricingText: "USD 99/Day",
      rating: 4.5,
    },

  ]
  const card_4 = [
    {
      bookurl: "https://cal.com/nitikatalwarr/workshops",
      imageSrc: "https://images.unsplash.com/photo-1571896349842-33c89424de2d?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&h=1024&w=768&q=80",
      title: "Healing with Science ",
      description: "Training in alternate sciences focuses on holistic practices such as acupressure, affirmations, meditation, healing, psychic powers, Zibu symbols, and angelic numbers. These practices promote physical, emotional, and spiritual well-being, help to develop intuitive abilities, and enhance spiritual growth and self-discovery.       ",
      locationText: "Rome, Italy",
      pricingText: "USD 39/Day",
      rating: "4.8",
    },
    {
      bookurl: "https://cal.com/nitikatalwarr/workshops",
      imageSrc: "https://images.unsplash.com/photo-1571896349842-33c89424de2d?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&h=1024&w=768&q=80",
      title: "Emergency Management ",
      description: "Training on emergency and pain management involves understanding symptoms, diseases, administering first aid, the role of different medical fields, helpline numbers, and pre-doctor techniques.It's crucial to recognize symptoms, learn how to analyze diseases, and implement first aid techniques like RICE method, proper CPR technique etc, and know helpline numbers and resources available in your area.      ",
      locationText: "Rome, Italy",
      pricingText: "USD 39/Day",
      rating: "4.8",
    },
    {
      bookurl: "https://cal.com/nitikatalwarr/workshops",
      imageSrc: "https://images.unsplash.com/photo-1571003123894-1f0594d2b5d9?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&h=1024&w=768&q=80",
      title: "Self Care",
      description: "Training on self-care includes developing self-care routines,skincare and haircare,self-massage techniques, creating packs and masksfrom natural ingredients, utilizing technology, and habit tracking. These practices help individuals prioritize their well-being and create balance and structure in their lives. ",
      locationText: "Ibiza, Spain",
      pricingText: "USD 50/Day",
      rating: 4.9,
    },
    {
      bookurl: "https://cal.com/nitikatalwarr/workshops",
      imageSrc: "https://images.unsplash.com/photo-1549294413-26f195200c16?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&h=1024&w=768&q=80",
      title: "Handling Hormones ",
      description: "The Hormonal Health training program provides a comprehensive understanding of hormones, including the science and psychology behind them. The training covers strategies for balancing and supporting hormones and their relationship with neurotransmitters.It isideal for those looking to improve their hormonal health and wellbeing.",
      locationText: "Palo Alto, CA",
      pricingText: "USD 19/Day",
      rating: "5.0",
    },
    {
      bookurl: "https://cal.com/nitikatalwarr/workshops",
      imageSrc: "https://images.unsplash.com/photo-1571770095004-6b61b1cf308a?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&h=1024&w=768&q=80",
      title: "Acupressure for Daily Life:",
      description: "This Training program contains basic life problems, diagnosis and treatment by acupressure. After completing this training, you can solve your everyday health problems in 5 minutes!",
      locationText: "Arizona, RAK",
      pricingText: "USD 99/Day",
      rating: 4.5,
    },

  ]

  return (
    <Container>
      <Content>
        <HeadingWithControl>
          <Heading>Corporate Workshops/Trainings:-</Heading>
          <Controls>
            <PrevButton onClick={sliderRef1?.slickPrev}><ChevronLeftIcon /></PrevButton>
            <NextButton onClick={sliderRef1?.slickNext}><ChevronRightIcon /></NextButton>
          </Controls>
        </HeadingWithControl>
        <CardSlider ref={setSliderRef1} {...sliderSettings}>
          {card_1.map((card, index) => (
            <Card key={index}>
              {/* <CardImage imageSrc={card.imageSrc} /> */}
              <TextInfo>
                <TitleReviewContainer>
                  <Title>{card.title}</Title>
                  {/* <RatingsInfo>
                    <StarIcon />
                    <Rating>{card.rating}</Rating>
                  </RatingsInfo> */}
                </TitleReviewContainer>
                {/* <SecondaryInfoContainer>
                  <IconWithText>
                    <IconContainer>
                      <LocationIcon />
                    </IconContainer>
                    <Text>{card.locationText}</Text>
                  </IconWithText>
                  <IconWithText>
                    <IconContainer>
                      <PriceIcon />
                    </IconContainer>
                    <Text>{card.pricingText}</Text>
                  </IconWithText>
                </SecondaryInfoContainer> */}
                <Description>{card.description}</Description>
              </TextInfo>
              <PrimaryButton href={card.bookurl}>
                Book Now
                </PrimaryButton>
            </Card>
          ))}
        </CardSlider>
        <HeadingWithControl>
          <Heading>Student Workshops/Trainings:-</Heading>
          <Controls>
            <PrevButton onClick={sliderRef2?.slickPrev}><ChevronLeftIcon /></PrevButton>
            <NextButton onClick={sliderRef2?.slickNext}><ChevronRightIcon /></NextButton>
          </Controls>
        </HeadingWithControl>
        <CardSlider ref={setSliderRef2} {...sliderSettings}>
          {card_2.map((card, index) => (
            <Card key={index}>
              {/* <CardImage imageSrc={card.imageSrc} /> */}
              <TextInfo>
                <TitleReviewContainer>
                  <Title>{card.title}</Title>
                  {/* <RatingsInfo>
                    <StarIcon />
                    <Rating>{card.rating}</Rating>
                  </RatingsInfo> */}
                </TitleReviewContainer>
                <SecondaryInfoContainer>
                  {/* <IconWithText>
                    <IconContainer>
                      <LocationIcon />
                    </IconContainer>
                    <Text>{card.locationText}</Text>
                  </IconWithText> */}
                  {/* <IconWithText>
                    <IconContainer>
                      <PriceIcon />
                    </IconContainer>
                    <Text>{card.pricingText}</Text>
                  </IconWithText> */}
                </SecondaryInfoContainer>
                <Description>{card.description}</Description>
              </TextInfo>
              <PrimaryButton href={card.bookurl}>
                Book Now
              </PrimaryButton>
            </Card>
          ))}
        </CardSlider>
        <HeadingWithControl>
          <Heading>Psychology based:-</Heading>
          <Controls>
            <PrevButton onClick={sliderRef3?.slickPrev}><ChevronLeftIcon /></PrevButton>
            <NextButton onClick={sliderRef3?.slickNext}><ChevronRightIcon /></NextButton>
          </Controls>
        </HeadingWithControl>
        <CardSlider ref={setSliderRef3} {...sliderSettings}>
          {card_3.map((card, index) => (
            <Card key={index}>
              {/* <CardImage imageSrc={card.imageSrc} /> */}
              <TextInfo>
                <TitleReviewContainer>
                  <Title>{card.title}</Title>
                  {/* <RatingsInfo>
                    <StarIcon />
                    <Rating>{card.rating}</Rating>
                  </RatingsInfo> */}
                </TitleReviewContainer>
                <SecondaryInfoContainer>
                  {/* <IconWithText>
                    <IconContainer>
                      <LocationIcon />
                    </IconContainer>
                    <Text>{card.locationText}</Text>
                  </IconWithText> */}
                  {/* <IconWithText>
                    <IconContainer>
                      <PriceIcon />
                    </IconContainer>
                    <Text>{card.pricingText}</Text>
                  </IconWithText> */}
                </SecondaryInfoContainer>
                <Description>{card.description}</Description>
              </TextInfo> 
                 <PrimaryButton href={card.bookurl}>
                Book Now
              </PrimaryButton> </Card>
          ))}
        </CardSlider>
        <HeadingWithControl>
          <Heading>Health based:-</Heading>
          <Controls>
            <PrevButton onClick={sliderRef4?.slickPrev}><ChevronLeftIcon /></PrevButton>
            <NextButton onClick={sliderRef4?.slickNext}><ChevronRightIcon /></NextButton>
          </Controls>
        </HeadingWithControl>
        <CardSlider ref={setSliderRef4} {...sliderSettings}>
          {card_4.map((card, index) => (
            <Card key={index}>
              {/* <CardImage imageSrc={card.imageSrc} /> */}
              <TextInfo>
                <TitleReviewContainer>
                  <Title>{card.title}</Title>
                  {/* <RatingsInfo>
                    <StarIcon />
                    <Rating>{card.rating}</Rating>
                  </RatingsInfo> */}
                </TitleReviewContainer>
                <SecondaryInfoContainer>
                  {/* <IconWithText>
                    <IconContainer>
                      <LocationIcon />
                    </IconContainer>
                    <Text>{card.locationText}</Text>
                  </IconWithText> */}
                  {/* <IconWithText>
                    <IconContainer>
                      <PriceIcon />
                    </IconContainer>
                    <Text>{card.pricingText}</Text>
                  </IconWithText> */}
                </SecondaryInfoContainer>
                <Description>{card.description}</Description>
              </TextInfo>
              <PrimaryButton href={card.bookurl}>
                Book Now
                </PrimaryButton>
             </Card>
          ))}
        </CardSlider>
      </Content>
      
      <Note>CUSTOMIZED TRAININGS & WORKSHOPS AVAILABLE!</Note>
    </Container>
  );
};
