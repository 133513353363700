import React from "react";
import tw from "twin.macro"; //eslint-disable-line
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import MainFeat from "components/features/TwoColSingleFeatureWithStats2.js";
import Hero from "components/hero/TwoColumnWithPrimaryBackground.js";
import Features from "components/features/ThreeColWithSideImageWithPrimaryBackground.js";
import SectionsServices from "components/pricing/ThreePlansWithHalfPrimaryBackground"
import Testimonial from "components/testimonials/SimplePrimaryBackground.js";
import FAQ from "components/faqs/TwoColumnPrimaryBackground.js";
import Footer from "components/footers/FiveColumnDark.js";
// const HighlightedText = tw.span`[background: #ff288e] text-gray-100 px-4 transform -skew-x-12 inline-block`;
  

export default () => {
  return (
    <AnimationRevealPage direction="left">
      <Hero />
      <SectionsServices/>
      <MainFeat/>
      <Features />
      <Testimonial />
      {/* <FAQ /> */}
      <Footer />
    </AnimationRevealPage>
  );
}
