import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line

import LogoImage from "images/nitika/Soma by Nitika.png";
import { ReactComponent as FacebookIcon } from "images/instagram.svg";
import { ReactComponent as TwitterIcon } from "images/linkedin.svg";
const Container = tw.div`relative [background: #F496C3] text-gray-100 -mx-8 -mb-8 px-8`;
const Content = tw.div`max-w-screen-xl mx-auto pt-16 pb-8`
const FiveColumns = tw.div`flex flex-wrap justify-between`;
const Column = tw.div`w-1/2 md:w-1/5 mb-8 md:mb-0 text-sm sm:text-base text-center md:text-left`;
const CompanyColumn = tw.div`text-center md:text-left mb-16 lg:mb-0 w-full lg:w-1/5`;
const ColumnHeading = tw.h5`font-bold uppercase`;
const LinkList = tw.ul`mt-4 text-sm font-medium`;
const LinkListItem = tw.li`mt-3`;
const Link = tw.a`border-b-2 border-transparent hocus:text-gray-300 hocus:border-gray-100 pb-1 transition duration-300`;

const LogoContainer = tw.div`flex items-center justify-center lg:justify-start`;
const LogoImg = tw.img`w-[5rem]`;
const LogoText = tw.h5`text-xl font-black`;

const CompanyAddress = tw.p`mt-4 max-w-xs font-medium text-sm mx-auto lg:mx-0 lg:mr-4 leading-loose text-center lg:text-left`;

const SocialLinksContainer = tw.div`mt-4 text-center lg:text-left`;
const SocialLink = styled.a`
  ${tw`cursor-pointer inline-block p-2 rounded-full bg-gray-100 text-gray-900 hover:bg-gray-500 transition duration-300 mr-4 last:mr-0`}
  svg {
    ${tw`w-4 h-4`}
  }
`;
const CopyrightAndCompanyInfoRow = tw.div`pb-0 text-sm font-normal flex flex-col sm:flex-row justify-between items-center`
const CopyrightNotice = tw.div``
// const CompanyInfo = tw.div``

const Divider = tw.div`my-8 border-b-2 border-gray-800`
export default () => {
  return (
    <Container id="contact">
      <Content>
        <FiveColumns>
          <CompanyColumn>
            <LogoContainer>
              <LogoImg src={LogoImage} />
            </LogoContainer>
            <CompanyAddress>
              <LogoText>Soma by Nitika</LogoText>
            </CompanyAddress>
            <SocialLinksContainer>
              <SocialLink href="https://www.instagram.com/nitikatalwarr?igsh=eTZmenp0NTVqbWl2&utm_source=qr" target="_blank">
                <FacebookIcon />
              </SocialLink>
              <SocialLink href="https://www.linkedin.com/in/nitika-talwar-3a07591a6/" target="_blank">
                <TwitterIcon />
              </SocialLink>
            </SocialLinksContainer>
          </CompanyColumn>
          <Column>
            <ColumnHeading>Quick Links</ColumnHeading>
            <LinkList>
              <LinkListItem>
                <Link href="/therapy">Therapy Services</Link>
              </LinkListItem>
              <LinkListItem>
                <Link href="/workshop">Workshops/Trainings</Link>
              </LinkListItem>
              <LinkListItem>
                <Link href="mailto:Somabynitika@gmail.com">Contact Us</Link>
              </LinkListItem>
              <LinkListItem>
                <Link href="#about">About Us</Link>
              </LinkListItem>
            </LinkList>
          </Column>

          {/* <Column>
            <ColumnHeading>Legal</ColumnHeading>
            <LinkList>
              <LinkListItem>
                <Link href="#">GDPR</Link>
              </LinkListItem>
              <LinkListItem>
                <Link href="#">Privacy Policy</Link>
              </LinkListItem>
              <LinkListItem>
                <Link href="#">Terms of Service</Link>
              </LinkListItem>
              <LinkListItem>
                <Link href="#">Disclaimer</Link>
              </LinkListItem>
            </LinkList>
          </Column> */}
          <Column>
            <ColumnHeading>Contact</ColumnHeading>
            <LinkList>
              <LinkListItem>
              <Link href="tel:+91 99901 30699">
              +91 99901 30699
              </Link>
              </LinkListItem>
              <LinkListItem>
                <Link href="mailto:Somabynitika@gmail.com">Somabynitika@gmail.com</Link>
              </LinkListItem>
              {/* <LinkListItem>
                <Link href="#">Sales</Link>
              </LinkListItem>
              <LinkListItem>
                <Link href="#">Report Abuse</Link>
              </LinkListItem> */}
            </LinkList>
          </Column>
        </FiveColumns>
        <Divider />
        <CopyrightAndCompanyInfoRow>
          <CopyrightNotice>&copy; Copyright 2024, Nitika Talwar Inc.</CopyrightNotice>
          {/* <CompanyInfo>An Internet Company.</CompanyInfo> */}
        </CopyrightAndCompanyInfoRow>
      </Content>
    </Container>
  );
};
